<template>
  <section>
    <div class="box p-4 mt-2">
      <div class="md:flex md:justify-between items-center">
        <div class="flex gap-2 items-center">
          <i class="pi pi-copy text-xl"></i>
          <p class="font-bold text-xl">Contratos</p>
        </div>
        <Button label="Crear contrato" v-if="$can('pharmasan.ventas.contrato-precio.crear')" @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.crear' })" severity="success" icon="pi pi-plus" />
      </div>
      <div  class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        <div class="w-full">
          <label class="text-gray-600 text-xs italic" for="cliente">No. Contrato</label>
          <InputText class="w-full" type="text" v-model="filtros.NumCto" />
        </div>
        <div class="w-full">
          <label class="text-gray-600 text-xs italic" for="cliente">Cliente</label>
          <Dropdown
            v-model="filtros.ClienteId"
            :options="contratosStore._listado_clientes"
            optionLabel="CardName"
            optionValue="Id"
            class="w-full md:w-14rem"
          />
        </div>
        <div class="w-full">
          <label class="text-gray-600 text-xs italic" for="cliente">Estado</label>
          <Dropdown
            v-model="filtros.Status"
            :options="[{name: 'Activo', id: 1}, {name: 'Inactivo', id: 0}]"
            optionLabel="name"
            optionValue="id"
            :show-clear="true"
            class="w-full md:w-14rem"
          />
        </div>
        <div class="flex gap-2 pt-5">
          <Button label="Buscar" @click="paginar" class="w-6/12" icon="pi pi-search" />
          <Button label="Limpiar" @click="limpiarFiltros" class="w-6/12" icon="pi pi-times" severity="secondary" />
        </div>
      </div>
      <div class="w-full mt-4">
        <DataTable class="p-datatable-sm" :show-gridlines="true" :value="contratosStore._paginado_contratos.rows" tableStyle="min-width: 50rem">
          <Column field="NumCto" header="No. Contrato"></Column>
          <Column field="NombreCliente" header="Cliente"></Column>
          <Column :style="{ width: '15rem' }" field="Descript" header="Descripción"></Column>
          <Column field="ValorTotal" header="Valor total"></Column>
          <Column field="FechaInicio" header="Fecha inicio / Fecha fin">
            <template #body="slotProps">
              <div class="text-sm">
                <!-- <p class="font-medium">Inicio - Fin: </p> -->
                <div class="flex gap-2">
                  <p>{{ slotProps.data.FechaInicio ? $h.formatDate(slotProps.data.FechaInicio, 'YYYY-MM-DD') : 'Sin registros' }}</p>
                  <p> - </p>
                  <p>{{ slotProps.data.FechaFin ? $h.formatDate(slotProps.data.FechaFin, 'YYYY-MM-DD') : 'Sin registros' }}</p>
                </div>
              </div>
            </template>
          </Column>
          <Column field="CreatedAt" header="Creación / Actualización">
            <template #body="slotProps">
              <div class="text-sm">
                <div class="flex gap-2">
                  <p class="font-medium">Creación: </p>
                  <p>{{ slotProps.data.CreatedAt ? $h.formatDate(slotProps.data.CreatedAt, 'YYYY-MM-DD hh:mm A') : 'Sin registros' }}</p>
                </div>
                <div class="flex gap-2">
                  <p class="font-medium">Actualización: </p>
                  <p>{{ slotProps.data.UpdatedAt ? $h.formatDate(slotProps.data.UpdatedAt, 'YYYY-MM-DD hh:mm A') : 'Sin registros' }}</p>
                </div>
              </div>
            </template>
          </Column>
          <Column header="Motivos de autorización" class="w-40">
            <template #body="slotProps">
              <div v-if="slotProps.data.motivos.length">
                <div v-for="(motivo,  i) in slotProps.data.motivos" :key="i">
                  <p class="text-xs italic font-medium">{{ motivo }}</p>
                </div>
              </div>
              <p class="text-xs text-gray-600 italic" v-else>No se encontraron motivos de autorización asociados a este contrato</p>
            </template>
          </Column>
          <Column header="Estado">
            <template #body="slotProps">
              <div class="font-bold flex gap-2 items-center" :class="slotProps.data.Status == 1 ? 'text-green-600' : slotProps.data.Status == 0 ? 'text-red-600' : 'text-gray-600'">
                <div class="w-2 h-2 rounded-full" :class="slotProps.data.Status == 1 ? 'bg-green-600' : slotProps.data.Status == 0 ? 'bg-red-600': 'bg-gray-600'"></div>
                <p>{{ slotProps.data.Status == 0 ? 'Inactivo' : slotProps.data.Status == 1 ? 'Activo' : 'Borrador' }}</p>
              </div>
            </template>
          </Column>
          <Column :style="{ width: '5rem' }" headerClass="text-center" header="Acciones">
            <template #body="slotProps">
              <div class="flex w-full justify-around">
                <button v-if="$can('pharmasan.ventas.contrato-precio.ver')" v-tooltip="'Ver contrato'"><i class="pi pi-eye" @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.ver', params: { id: slotProps.data.Id } })"></i></button>
                <button v-if="$can('pharmasan.ventas.contrato-precio.editar')" v-tooltip="'Editar contrato'"><i class="pi pi-pencil" @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.editar', params: { id: slotProps.data.Id } })"></i></button>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="limit" @page="onPageChanged" :totalRecords="contratosStore._paginado_contratos.count" :rowsPerPageOptions="[10, 20, 30]"></Paginator>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>
<script>
  import { onMounted, ref, computed } from 'vue'
  import { useContratosStore } from '../stores/contratos.store'
  export default {
    name: 'configuracionContratos',
    setup () {
      const contratosStore = useContratosStore()
      const limit = ref(10)
      const offset = ref(0)
      const filtros = ref({
        ClienteId: null,
        Status: 1,
        NumCto: null
      })
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value,
          ...filtros.value
        }
      })
      const paginar = () => { contratosStore.paginate(params.value) }
      const onPageChanged = ({ rows, first }) => {
        limit.value = rows
        offset.value = first
        paginar()
      }
      const limpiarFiltros = () => {
        filtros.value = {
          ClienteId: null,
          Status: null,
          NumCto: null
        }
        paginar()
      }
      const listadoContratos = ref([])
      onMounted(() => {
        paginar()
        contratosStore.obtenerClientes()
      })
      return {
        listadoContratos,
        params,
        limit,
        offset,
        contratosStore,
        onPageChanged,
        filtros,
        paginar,
        limpiarFiltros
      }
    }
  }
</script>
<style scoped>
</style>
