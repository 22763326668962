import http from '@/libs/http'
import { useStore } from '@/store'
import axios from 'axios'
const url = process.env.VUE_APP_MICROSERVICE_API_CONTRATO_PRECIO
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class ContratoPrecioService {
  paginate (params) {
    return http.get(`${url}/contratos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${url}/contratos/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crear (payload) {
    return http.post(`${url}/contratos`, payload, {
      header: {
        loading: true
      }
    })
  }

  put (payload) {
    return http.put(`${url}/contratos`, payload, {
      header: {
        loading: true
      }
    })
  }

  paginarZoificacionesContrato (id, params) {
    return http.get(`${url}/contratos/${id}/zonificacion`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginarPacientes (id, params) {
    return http.get(`${url}/contratos/${id}/pacientes`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginarArticulos (idContrato, params) {
    return http.get(`${url}/contratos/${idContrato}/articulos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarClientes () {
    return http.get(`${url}/clientes/listar`, {
      headers: {
        loading: true
      }
    })
  }

  listarMotivosAutorizacion () {
    return http.get(`${url}/mot-autoriza/listar`, {
      headers: {
        loading: true
      }
    })
  }

  listarDepartamentos (nombre) {
    return http.get(`${url}/localizacion/listar-dep${nombre ? ('/' + nombre) : ''}`, {
      headers: {
        loading: true
      }
    })
  }

  listarMunicipios (params, nombre) {
    return http.get(`${url}/localizacion/listar-mun/${nombre}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarRegimenes () {
    return http.get(`${url}/regimen/listar`, {
      headers: {
        loading: true
      }
    })
  }

  listarModalidadesContrato () {
    return http.get(`${url}/mod-contrato/listar`, {
      headers: {
        loading: true
      }
    })
  }

  listarArticulos (idContrato) {
    return http.get(`${url}/contratos/${idContrato}/articulos`, {
      headers: {
        loading: true
      }
    })
  }

  descargarPlantillaPacientesMasivo () {
    const send = `${url}/templates/pacientes`
    const store = useStore()
    const params = { token: `Bearer ${store.getters['auth/getToken']}` }
    const res = http.getUri({ url: send, params })
    window.open(res, 'blank')
  }

  descargarPlantillaArticulosMasivo () {
    const send = `${url}/templates/articulos`
    const store = useStore()
    const params = { token: `Bearer ${store.getters['auth/getToken']}` }
    const res = http.getUri({ url: send, params })
    window.open(res, 'blank')
  }

  validPacientes (payload) {
    return http.post(`${url}/contratos/valid-pacientes`, payload, {
      headers: {
        loading: true
      }
    })
  }

  validArticulos (payload, id) {
    return http.post(`${url}/contratos/valid-articulos/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  crearPacientesContrato (id, payload) {
    return http.post(`${url}/contratos/${id}/pacientes`, payload, {
      headers: {
        loading: true
      }
    })
  }

  listarTiposId () {
    return http.get(`${baseUrl}/pacientes/tipos-id`, {
      headers: {
        loading: true
      }
    })
  }

  cambiarEstadoPaciente (idContrato, idPaciente, payload) {
    return http.put(`${url}/contratos/${idContrato}/pacientes/${idPaciente}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  cambiarEstadoArticulo (idContrato, idArticulo, payload) {
    return http.put(`${url}/contratos/${idContrato}/articulos/${idArticulo}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  crearArticulosContrato (idContrato, payload) {
    return http.post(`${url}/contratos/${idContrato}/articulos`, payload, {
      headers: {
        loading: true
      }
    })
  }

  guardarSoporteContrato (idContrato, payload) {
    return http.post(`${url}/contratos/${idContrato}/soportes`, payload, {
      headers: {
        loading: true
      }
    })
  }

  verPdf (ruta) {
    const store = useStore()
    return new Promise((resolve) => {
      const baseUrl = `${url}/contratos/soporte/ver`
      const token = store.getters['auth/getToken']
      console.log('el token-->', token)
      const res = axios.getUri({ url: baseUrl, params: { token: `Bearer ${token}`, path: ruta } })
      resolve(res)
    })
  }

  actualizarPacientesMasivo (id, payload) {
    return http.put(`${url}/contratos/actualizar-pacientes-masivo/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  obtenerEps (params) {
    return http.get(`${url}/contratos/eps`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  validarContratos (params) {
    return http.get(`${url}/contratos/valid-contratos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  contratosPorListaPrecio (id) {
    return http.get(`${url}/contratos/contratos-lista-precio/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  conteoPacientesNoProcesados (contratoId) {
    return http.get(`${url}/contratos/conteo-pacientes-no-procesados/${contratoId}`, {
      headers: {
        loading: true
      }
    })
  }

  exportarPacientesNoProcesados (contratoId) {
    const baseUrl = `${url}/contratos/exportar-no-procesados/${contratoId}`
    const store = useStore()
    const token = store.getters['auth/getToken']
    const res = axios.getUri({ url: baseUrl, params: { token: `Bearer ${token}` } })
    window.open(res, '_blank')
  }

  postCtoMotAutoriza (contratoId, body) {
    return http.put(`${url}/contratos/actualizar-motivos-contrato/${contratoId}`, body, {
      headers: {
        loading: true
      }
    })
  }
}
