import { defineStore } from 'pinia'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import ContratoPrecioService from '../services/contrato-precio.service'
const _ContratoPrecioService = new ContratoPrecioService()

export const useContratosStore = defineStore('contratosStore', {
  state: () => ({
    paginado_contratos: {},
    listado_clientes: [],
    listado_motivos_autorizacion: [],
    zonificaciones: [
      {
        departamento: 0,
        id: 1,
        fecha_inicio: '',
        fecha_fin: '',
        regimenes: [],
        valor_maximo: 0,
        municipios: [],
        municipios_temp: [],
        display_modal: false
      }
    ],
    articulos: {
      lista_precio_id: 0,
      articulos: []
    },
    zonificaciones_buscadas: [],
    listado_regimenes: [],
    listado_municipios: [],
    listado_departamentos: [],
    header: {
      cliente: '',
      Descript: '',
      NumCto: '',
      ValorTotal: 0,
      MotivosAutorizacion: [],
      Eps: [],
      fecha_inicio: '',
      fecha_fin: ''
    },
    pacientes_ingresados: '',
    paciente_ingresar: {
      TipoIdent: '',
      NoIdent: '',
      FechaInicio: '',
      FechaFin: ''
    },
    creando: false,
    contrato: {
      Id: 0,
      CardCode: '',
      Descript: '',
      NumCto: '',
      ValorTotal: null,
      Status: 0,
      ListPrice: 0,
      NumAdicion: 0,
      ClienteId: 0,
      Cliente: {
          CardCode: '',
          CardName: '',
          U_PHR_CardName: '',
          Address: '',
          CardType: '',
          LicTradNum: '',
          GroupCode: 0,
          Id: 0
      },
      ListaPrecio: {
          Id: 0,
          ListPriceCL: 0,
          Price: '',
          ItemCode: '',
          ArticuloId: null,
          createdAt: null,
          updatedAt: null,
          deletedAt: null,
          createdBy: 0,
          updatedBy: null,
          deletedBy: null
      },
      CtoPac: [],
      CtoMotAutoriza: [
          {
              Id: 0,
              ContratoId: 0,
              MotAutorizaId: 0,
              MotAutoriza: [
                  {
                      Id: 0,
                      Nombre: '',
                      Codigo: '',
                      CodModContrato: '',
                      createdAt: '',
                      updatedAt: '',
                      deletedAt: null,
                      createdBy: null,
                      updatedBy: null,
                      deletedBy: null
                  }
              ]
          }
      ]
    },
    paginado_zonificacion: {},
    paginado_pacientes: {
      count: 0,
      rows: []
    },
    listado_modalidades_contrato: [],
    modalidad_contrato_seleccionada: 0,
    soportes: [],
    medicamentos_ingresados: null,
    tipos_identificacion: [],
    paginado_articulos: {
      rows: [],
      count: 0
    },
    eps_encontradas: []
  }),
  getters: {
    _paginado_contratos: state => state.paginado_contratos,
    _listado_clientes: state => state.listado_clientes,
    _listado_motivos_autorizacion: state => state.listado_motivos_autorizacion,
    _zonificaciones: state => state.zonificaciones,
    _zonificaciones_buscadas: state => state.zonificaciones_buscadas,
    _listado_regimenes: state => state.listado_regimenes,
    _listado_municipios: state => state.listado_municipios,
    _listado_departamentos: state => state.listado_departamentos,
    _header: state => state.header,
    _creando: state => state.creando,
    _contrato: state => state.contrato,
    _paginado_zonificacion: state => state.paginado_zonificacion,
    _paginado_pacientes: state => state.paginado_pacientes,
    _articulos: state => state.articulos,
    _pacientes_ingresados: state => state.pacientes_ingresados,
    _listado_modalidades_contrato: state => state.listado_modalidades_contrato,
    _modalidad_contrato_seleccionada: state => state.modalidad_contrato_seleccionada,
    _soportes: state => state.soportes,
    _medicamentos_ingresados: state => state.medicamentos_ingresados,
    _tipos_identificacion: state => state.tipos_identificacion,
    _paciente_ingresar: state => state.paciente_ingresar,
    _paginado_articulos: state => state.paginado_articulos,
    _eps_encontradas: state => state.eps_encontradas
  },
  actions: {
    paginate (params) {
      _ContratoPrecioService.paginate(params).then(({ data }) => {
        this.paginado_contratos = data
      })
    },
    obtenerClientes () {
      _ContratoPrecioService.listarClientes().then(({ data }) => {
        this.listado_clientes = data
      })
    },
    obtenerMotivosAutorizacion () {
      return _ContratoPrecioService.listarMotivosAutorizacion().then(({ data }) => {
        this.listado_motivos_autorizacion = data
        return data
      })
    },
    obtenerDepartamentos () {
      _ContratoPrecioService.listarDepartamentos().then(({ data }) => {
        this.listado_departamentos = data
      })
    },
    obtenerMunicipios (departamento, nombre) {
      return _ContratoPrecioService.listarMunicipios(departamento, nombre).then(({ data }) => {
        this.listado_municipios = data
        return data
      })
    },
    buscarZonificacion (zonificacion) {
      console.log('here', zonificacion)
    },
    obtenerRegimenes () {
      // OSCAR Y JUAN AUTORIZARON PARA QUE ESTA DATA QUEDARA QUEMADA ATT MARIA P
      this.listado_regimenes = ['SUBSIDIADO', 'CONTRIBUTIVO', 'MOVILIDAD CONTRIBUTIVO', 'MOVILIDAD SUBSIDIADO']
    },
    descargarPlantillaMasivoPacientes () {
      _ContratoPrecioService.descargarPlantillaPacientesMasivo()
    },
    descargarPlantillaMasivoArticulos () {
      _ContratoPrecioService.descargarPlantillaArticulosMasivo()
    },
    async crear (borrador = 1) {
      this.creando = true
      for (const [key, value] of Object.entries(this._header)) {
        if (key === 'Descript') continue
        if (key === 'ValorTotal') continue
        if (!value) return
      }
      const motivosAutorizacion = []
      for (const i of this._header.MotivosAutorizacion) {
        if (i.cobro_cuota_moderadora === null) {
          return Swal.fire({
            title: 'Error',
            text: 'Todos los motivos de autorización deben tener una opción de cobro de cuota moderadora',
            icon: 'error'
          }).then(() => {
            return 'Faltan datos'
          })
        } else if (i.cobro_cuota_recuperacion === null) {
          return Swal.fire({
            title: 'Error',
            text: 'Todos los motivos de autorización deben tener una opción de cobro de cuota de recuperación',
            icon: 'error'
          }).then(() => {
            return 'Faltan datos'
          })
        } else {
          motivosAutorizacion.push({
            MotAutorizaId: i.Id,
            CobroCuotaModeradora: i.cobro_cuota_moderadora,
            CobreoCuotaRecuperacion: i.cobro_cuota_recuperacion
          })
        }
      }
      let eps = []
      if (this._header.Eps.length) {
        eps = this._header.Eps.map(a => {
          return {
            Entidad: a.CardName,
            CodEps: a.CardCode
          }
        })
      }
      const municipios = []
      for (const i of this._zonificaciones) {
        if (i.municipios.length) {
          for (const j of i.municipios) {
            municipios.push({
              CodDane: j.CodDane,
              VlrBase: i.valor_maximo,
              FecInicio: i.fecha_inicio,
              FecFin: i.fecha_fin,
              Subsidiado: i.regimenes.some(a => a === 'SUBSIDIADO'),
              Contributivo: i.regimenes.some(a => a === 'CONTRIBUTIVO'),
              MovContributivo: i.regimenes.some(a => a === 'MOVILIDAD CONTRIBUTIVO'),
              MovSubsidiado: i.regimenes.some(a => a === 'MOVILIDAD SUBSIDIADO')
            })
          }
        } else {
          return Swal.fire({
            title: 'Error',
            text: 'Las zonificaciones deben tener al menos un municipio seleccionado',
            icon: 'error'
          }).then(() => {
            return 'Faltan datos'
          })
        }
      }
      const contrato = {
        ClienteId: this._header.cliente,
        Descript: this._header.Descript,
        NumCto: this._header.NumCto,
        FechaInicio: this._header.fecha_inicio,
        FechaFin: this._header.fecha_fin,
        ValorTotal: this._header?.ValorTotal,
        ListPrice: this._articulos.lista_precio_id.ListPriceCL,
        NumAdicion: 0,
        Status: borrador,
        CtoPac: this.pacientes_ingresados,
        CtoMotAutoriza: motivosAutorizacion,
        Soportes: this.soportes,
        CtoZonificacion: municipios,
        Articulos: this.medicamentos_ingresados,
        CtoEps: eps
      }
      // const respCrear = await
      return _ContratoPrecioService.crear(contrato).then((data) => {
        return data
      })
      // console.log('el contrato', contrato)
    },
    async actualizar (borrador = 1, Id) {
      this.creando = true
      for (const [key, value] of Object.entries(this._header)) {
        if (key === 'Descript') continue
        if (key === 'ValorTotal') continue
        if (!value) return
      }
      const motivosAutorizacion = []
      for (const i of this._header.MotivosAutorizacion) {
        if (i.cobro_cuota_moderadora === null) {
          return Swal.fire({
            title: 'Error',
            text: 'Todos los motivos de autorización deben tener una opción de cobro de cuota moderadora',
            icon: 'error'
          }).then(() => {
            return 'Faltan datos'
          })
        } else if (i.cobro_cuota_recuperacion === null) {
          return Swal.fire({
            title: 'Error',
            text: 'Todos los motivos de autorización deben tener una opción de cobro de cuota de recuperación',
            icon: 'error'
          }).then(() => {
            return 'Faltan datos'
          })
        } else {
          motivosAutorizacion.push({
            MotAutorizaId: i.Id,
            CobroCuotaModeradora: i.cobro_cuota_moderadora,
            CobreoCuotaRecuperacion: i.cobro_cuota_recuperacion
          })
        }
      }
      const municipios = []
      let eps = []
      if (this._header.Eps.length) {
        eps = this._header.Eps.map(a => {
          return {
            Entidad: a.CardName,
            CodEps: a.CardCode
          }
        })
      }
      for (const i of this._zonificaciones) {
        if (i.municipios.length) {
          for (const j of i.municipios) {
            municipios.push({
              CodDane: j.CodDane,
              VlrBase: i.valor_maximo,
              FecInicio: i.fecha_inicio,
              FecFin: i.fecha_fin,
              Subsidiado: i.regimenes.some(a => a === 'SUBSIDIADO'),
              Contributivo: i.regimenes.some(a => a === 'CONTRIBUTIVO'),
              MovContributivo: i.regimenes.some(a => a === 'MOVILIDAD CONTRIBUTIVO'),
              MovSubsidiado: i.regimenes.some(a => a === 'MOVILIDAD SUBSIDIADO')
            })
          }
        }
      }
      const contrato = {
        Id,
        ClienteId: this._header.cliente,
        Descript: this._header.Descript,
        NumCto: this._header.NumCto,
        ValorTotal: this._header?.ValorTotal,
        ListPrice: this._articulos.lista_precio_id.ListPriceCL,
        NumAdicion: 0,
        Status: borrador,
        CtoPac: this.pacientes_ingresados,
        CtoMotAutoriza: motivosAutorizacion,
        Soportes: this.soportes,
        CtoZonificacion: municipios,
        Articulos: this.medicamentos_ingresados,
        CtoEps: eps,
        FechaInicio: this._header.fecha_inicio,
        FechaFin: this._header.fecha_fin
      }
      return _ContratoPrecioService.put(contrato).then(({ data }) => {
        return data
      })
      // console.log('el contrato', contrato)
    },
    async findContrato (id) {
      return this.limpiarData().then(() => {
        return _ContratoPrecioService.find(id).then(({ data }) => {
          this.obtenerRegimenes()
          this.contrato = data
          const motivos = []
          if (data.CtoMotAutoriza.length) {
            for (const i of data.CtoMotAutoriza) {
              motivos.push({
                ...i.MotAutoriza,
                cobro_cuota_moderadora: i.CobroCuotaModeradora,
                cobro_cuota_recuperacion: i.CobroCuotaRecuperacion
              })
            }
          }
          if (data.CtoZonificacion.length) {
            this.zonificaciones = []
            for (const i of data.CtoZonificacion) {
              const object = {
                ...i,
                municipios_temp: [],
                display_modal: false
              }
              this.zonificaciones.push(object)
            }
          }
          if (data.Soportes.length) {
            this.soportes = []
            for (const i of data.Soportes) {
              this.soportes.push({
                nombre: i.Nombre,
                url: i.Url,
                id: i.Id
              })
            }
          }
          const eps = []
          if (data.CtoEps?.length) {
            for (const i of data.CtoEps) {
              eps.push({
                CardName: i.Entidad,
                CardCode: i.CodEps
              })
            }
          }
          this.header = {
            cliente: data.Cliente.Id,
            Descript: data.Descript,
            NumCto: data.NumCto,
            ValorTotal: data.ValorTotal,
            MotivosAutorizacion: motivos,
            Eps: eps,
            fecha_inicio: dayjs(data.FechaInicio).format('YYYY-MM-DD'),
            fecha_fin: dayjs(data.FechaFin).format('YYYY-MM-DD')
          }
          this.articulos.lista_precio_id = {
            ListPriceCL: data.ListPrice,
            Nombre: data.ListaPrecio?.Nombre ? data.ListaPrecio?.Nombre : data.ListPrice
          }
          return data
        })
      })
    },
    paginarZonificacion (id, params) {
      _ContratoPrecioService.paginarZoificacionesContrato(id, params).then(({ data }) => {
        this.paginado_zonificacion = data
        if (this.paginado_zonificacion.rows.length) {
          for (const i of this.paginado_zonificacion.rows) {
            i.display_modal = false
          }
        }
      })
    },
    paginarPacientes (id, params) {
      return _ContratoPrecioService.paginarPacientes(id, params).then(({ data }) => {
        this.paginado_pacientes.rows = data.rows
        this.paginado_pacientes.count = data.count
        return data
      })
    },
    limpiarData () {
      return new Promise((resolve, reject) => {
        const resetear = () => {
          this.paginado_contratos = {}
          this.listado_clientes = []
          this.soportes = []
          this.articulos = {
            lista_precio_id: 0,
            articulos: []
          }
          this.listado_motivos_autorizacion = []
          this.modalidad_contrato_seleccionada = 0
          this.zonificaciones = [
            {
              departamento: 0,
              id: 1,
              fecha_inicio: '',
              fecha_fin: '',
              regimenes: [],
              valor_maximo: 0,
              municipios: [],
              municipios_temp: [],
              display_modal: false
            }
          ]
          this.zonificaciones_buscadas = []
          this.listado_regimenes = []
          this.listado_municipios = []
          this.listado_departamentos = []
          this.header = {
            cliente: '',
            Descript: '',
            NumCto: '',
            ValorTotal: 0,
            MotivosAutorizacion: [],
            Eps: [],
            fecha_inicio: '',
            fecha_fin: ''
          }
          this.creando = false
          this.contrato = {
            Id: 0,
            CardCode: '',
            Descript: '',
            NumCto: '',
            ValorTotal: null,
            Status: 0,
            ListPrice: 0,
            NumAdicion: 0,
            ClienteId: 0,
            Cliente: {
                CardCode: '',
                CardName: '',
                U_PHR_CardName: '',
                Address: '',
                CardType: '',
                LicTradNum: '',
                GroupCode: 0,
                Id: 0
            },
            ListaPrecio: {
                Id: 0,
                ListPriceCL: 0,
                Price: '',
                ItemCode: '',
                ArticuloId: null,
                createdAt: null,
                updatedAt: null,
                deletedAt: null,
                createdBy: 0,
                updatedBy: null,
                deletedBy: null
            },
            CtoPac: [],
            CtoMotAutoriza: [
                {
                    Id: 0,
                    ContratoId: 0,
                    MotAutorizaId: 0,
                    MotAutoriza: [
                        {
                            Id: 0,
                            Nombre: '',
                            Codigo: '',
                            CodModContrato: '',
                            createdAt: '',
                            updatedAt: '',
                            deletedAt: null,
                            createdBy: null,
                            updatedBy: null,
                            deletedBy: null
                        }
                    ]
                }
            ]
          }
          this.paginado_zonificacion = {}
          this.paginado_pacientes = {}
        }
        resolve(resetear())
      })
    },
    listarModalidadesContrato () {
      _ContratoPrecioService.listarModalidadesContrato().then(({ data }) => {
        this.listado_modalidades_contrato = data
      })
    },
    crearPacientesContrato (id, pacientes) {
      return _ContratoPrecioService.crearPacientesContrato(id, pacientes || [this.paciente_ingresar]).then((data) => {
        return data
      })
    },
    listarTiposIdPaciente () {
      return _ContratoPrecioService.listarTiposId().then(({ data }) => {
        this.tipos_identificacion = data
        return data
      })
    },
    actualizarEstadoPaciente (idContrato, idPaciente, data) {
      return _ContratoPrecioService.cambiarEstadoPaciente(idContrato, idPaciente, data).then(({ data }) => {
        return data
      })
    },
    crearArticulosContrato (idContrato, payload) {
      return _ContratoPrecioService.crearArticulosContrato(idContrato, payload).then(({ data }) => {
        return data
      })
    },
    paginarArticulos (idContrato, params) {
      return _ContratoPrecioService.paginarArticulos(idContrato, params).then(({ data }) => {
        this.paginado_articulos.rows = data.rows.map(a => {
          return {
            ...a,
            precio_editar: a.Precio,
            editar: false
          }
        })
        this.paginado_articulos.count = data.count
        return data
      })
    },
    guardarSoporteContrato (idContrato, payload) {
      return _ContratoPrecioService.guardarSoporteContrato(idContrato, payload).then(({ data }) => {
        return data
      })
    },
    async descargarPdf (ruta) {
      await _ContratoPrecioService.verPdf(ruta).then((url) => {
        window.open(url, '_blank')
      })
    },
    cambiarEstadoArticuloContrato (idContrato, idArticulo, data) {
      return _ContratoPrecioService.cambiarEstadoArticulo(idContrato, idArticulo, data).then(({ data }) => {
        return data
      })
    },
    actualizarPacientesMasivo (id) {
      return _ContratoPrecioService.actualizarPacientesMasivo(id, this._pacientes_ingresados).then(({ data }) => {
        return data
      })
    },
    buscarEps (params) {
      return _ContratoPrecioService.obtenerEps(params).then(({ data }) => {
        let array = []
        if (data.length) {
          if (this._header.Eps.length) {
            for (const i of data) {
              if (!this._header.Eps.some(a => a.CardCode === i.CardCode)) {
                array.push(i)
              }
            }
          } else {
            array = data
          }
        }
        this.eps_encontradas = array
        return array
      })
    },
    validarContratos () {
      const params = {
        ClienteId: this._header.cliente,
        Motivos: this._header.MotivosAutorizacion.map(a => a.Id),
        Eps: this._header.Eps.map(a => a.CardCode)
      }
      return _ContratoPrecioService.validarContratos(params).then(({ data }) => {
        return data
      })
    },
    contratosPorListaPrecio (id) {
      return _ContratoPrecioService.contratosPorListaPrecio(id).then(({ data }) => {
        return data
      })
    },
    conteoPacientesNoProcesados (contratoId) {
      return _ContratoPrecioService.conteoPacientesNoProcesados(contratoId).then(({ data }) => {
        return data
      })
    },
    exportarPacientesNoProcesados (contratoId) {
      return _ContratoPrecioService.exportarPacientesNoProcesados(contratoId)
    },
    postCtoMotAutoriza (contratoId, body) {
      return _ContratoPrecioService.postCtoMotAutoriza(contratoId, body).then(({ data }) => {
        return data
      })
    }
  }
})
